import { render, staticRenderFns } from "./AssignMemberButton.vue?vue&type=template&id=2eff936b"
import script from "./AssignMemberButton.vue?vue&type=script&lang=ts"
export * from "./AssignMemberButton.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports