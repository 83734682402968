


















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
	name: "AssignMemberButton",
	props: {
		selectedMembers: {
			type: Array,
			required: true,
		},
	},
	emits: ["click"],
});
